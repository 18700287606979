import Partner from '../session-client/partner';
import VerificationScriptResource from '../session-client/verification-script-resource';
import Context from '../session-client/context';
import { OmidPartnerName, OmidPartnerVersion } from '../common/constants';
import AdSession from '../session-client/ad-session';

export default class Viewability {
  STATIC_URL = 'https://http2.mlstatic.com/frontend-assets/frontend-viewability';

  SDK_URL = 'omweb-v1.js';

  MEASUREMENT_SCRIPT_URL = 'measurement-script.js';

  VENDOR_KEY = '';

  VERIFICATIONS_PARAMETER = '';

  constructor(sdkUrl?: string, measurementScriptUrl?: string) {
    this.SDK_URL = `${this.STATIC_URL}/omweb-v1.js`;
    this.MEASUREMENT_SCRIPT_URL = `${this.STATIC_URL}/measurement-script.js`;
    const version = this.getCookie('viewability-version');

    if (version !== '') {
      this.SDK_URL = `${this.STATIC_URL}/omweb-v1.js`;
      this.MEASUREMENT_SCRIPT_URL = `${this.STATIC_URL}/measurement-script.${version}.js`;
    }

    if (sdkUrl) {
      this.SDK_URL = sdkUrl;
    }

    if (measurementScriptUrl) {
      this.MEASUREMENT_SCRIPT_URL = measurementScriptUrl;
    }
  }

  getCookie = (name: string) => document.cookie
    .split(';')
    .reduce(
      (acc: string, item: string) => (item.trim().indexOf(name) === 0 ? acc + item.replace(`${name}=`, '').trim() : acc),
      '',
    );

  addSession = (element: Element, trackUrl: string) => {
    const iframe = this.createOmsdkIframe();
    iframe.addEventListener(
      'load',
      () => {
        const session = this.createAdSession(iframe)(element, trackUrl);
        session.start();
      },
    );
    document.body.appendChild(iframe);
  };

  createOmsdkIframe = () => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.srcdoc = `<script defer src="${this.SDK_URL}"></script>`;

    return iframe;
  };

  createAdSession = (omsdkIframe: HTMLIFrameElement) => (element: Element, trackUrl: string) => {
    const partner = new Partner(OmidPartnerName, OmidPartnerVersion);
    const verificationScriptResource = new VerificationScriptResource(
      this.MEASUREMENT_SCRIPT_URL,
      this.VENDOR_KEY,
      this.VERIFICATIONS_PARAMETER,
    );
    const customReferenceData = '';

    const context = new Context(
      partner,
      [verificationScriptResource],
      trackUrl,
      customReferenceData,
    );

    context.underEvaluation = false;
    context.setSlotElement(element);
    const serviceWindow = omsdkIframe.contentWindow;

    if (!serviceWindow) {
      throw new Error('OM SDK iframe content window not available.');
    }
    context.setServiceWindow(serviceWindow);
    return new AdSession(context);
  };
}
