const SEMVER_DIGITS_NUMBER = 3;

export const isValidVersion = (version: string) => /\d+\.\d+\.\d+(-.*)?/.test(version);

export const versionGreaterOrEqual = (baseVersion: string, versionToCompare: string) => {
  const baseComponents = baseVersion.split('-')[0].split('.');
  const toCompareComponents = versionToCompare.split('-')[0].split('.');

  for (let i = 0; i < SEMVER_DIGITS_NUMBER; i += 1) {
    const baseNumber = parseInt(baseComponents[i], 10);
    const toCompareNumber = parseInt(toCompareComponents[i], 10);
    if (baseNumber > toCompareNumber) {
      return true;
    } if (baseNumber < toCompareNumber) {
      return false;
    }
  }
  return true;
};
