
let omidGlobal: Window | typeof globalThis;

const getOmidGlobal = (): Window | typeof globalThis => {
  if (typeof omidGlobal !== 'undefined' && omidGlobal) {
    return omidGlobal;
  }
  if (typeof global !== 'undefined' && global) {
    return global;
  }
  if (typeof window !== 'undefined' && window) {
    return window;
  }
  if (typeof globalThis !== 'undefined' && globalThis) {
    return globalThis;
  }
  const globalObject: Window = (Function('return this')());
  if (globalObject) return globalObject;

  throw new Error('Could not determine global object context.');
};

omidGlobal = getOmidGlobal();

export default {
  omidGlobal,
};
