export default class Partner {
  name: string;

  version: string;

  constructor(name: string, version: string) {
    this.name = name;
    this.version = version;
  }
}
