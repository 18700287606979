const GUID_KEY = 'omid_message_guid';
const METHOD_KEY = 'omid_message_method';
const VERSION_KEY = 'omid_message_version';
const ARGS_KEY = 'omid_message_args';

export interface SerializedMessage {
  [GUID_KEY]: string
  [METHOD_KEY]: string
  [VERSION_KEY]: string
  [ARGS_KEY]?: string | unknown[]
}

class InternalMessage {
  static deserialize(message: SerializedMessage) {
    return new InternalMessage(
      message[GUID_KEY],
      message[METHOD_KEY],
      message[VERSION_KEY],
      message[ARGS_KEY],
    );
  }

  guid: string;

  method: string;

  version: string;

  args?: string | unknown[];

  constructor(guid: string, method: string, version: string, args?: string | unknown[]) {
    this.guid = guid;
    this.method = method;
    this.version = version;
    this.args = args;
  }

  serialize() {
    const exported: SerializedMessage = {
      [GUID_KEY]: this.guid,
      [METHOD_KEY]: this.method,
      [VERSION_KEY]: this.version,
    };
    if (this.args !== undefined) {
      exported[ARGS_KEY] = this.args;
    }
    return exported;
  }
}

export default InternalMessage;
