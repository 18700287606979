
import Communication from './comunication';
import { CommunicationType } from './constants';
import InternalMessage, { SerializedMessage } from './internal-message';

class DirectCommunication extends Communication {
  constructor(to: any) {
    super(to);
    this.communicationType_ = CommunicationType.DIRECT;
    this.handleExportedMessage = DirectCommunication.prototype.handleExportedMessage.bind(this);
  }

  sendMessage(message: InternalMessage, to = this.to) {
    if (!to) {
      throw new Error('Message destination must be defined at construction '
                      + 'time or when sending the message.');
    }
    to.handleExportedMessage(message.serialize(), this);
  }

  handleExportedMessage(exportedMessage: SerializedMessage, from: unknown) {
    this.handleMessage(
      InternalMessage.deserialize(exportedMessage),
      from,
    );
  }

  isCrossOrigin() {
    return false;
  }
}

export default DirectCommunication;
