export function generateGuid(): string {
  const digit = (containsClockSeqHiAndReserved: boolean) => {
    const randomNumber = Math.random() * 16 | 0;
    if (containsClockSeqHiAndReserved) {
      return ((randomNumber & 0x3) | 0x8).toString(16);
    }
    return randomNumber.toString(16);
  };

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g, character => digit(character === 'y'),
  );
}
