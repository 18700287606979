import { AccessMode } from '../common/constants';

export default class VerificationScriptResource {
  resourceUrl: string;

  vendorKey?: string;

  verificationParameters?: string;

  accessMode: string;

  constructor(resourceUrl: string, vendorKey?: string, verificationParameters?: string, accessMode: string = AccessMode.FULL) {
    this.resourceUrl = resourceUrl;
    this.vendorKey = vendorKey;
    this.verificationParameters = verificationParameters;
    this.accessMode = accessMode;
  }

  serialize() {
    return {
      accessMode: this.accessMode,
      resourceUrl: this.resourceUrl,
      vendorKey: this.vendorKey,
      verificationParameters: this.verificationParameters,
    };
  }
}

