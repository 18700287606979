import Partner from './partner';
import VerificationScriptResource from './verification-script-resource';

class Context {
  partner: Partner;

  verificationScriptResources: VerificationScriptResource[];

  contentUrl: (string | null);

  customReferenceData: string | null;

  slotElement: Element | null;

  underEvaluation: boolean;

  serviceWindow: unknown;

  constructor(
    partner: Partner,
    verificationScriptResources: VerificationScriptResource[],
    contentUrl: (string | null) = null,
    customReferenceData: string | null = null,
  ) {
    this.partner = partner;
    this.verificationScriptResources = verificationScriptResources;
    this.slotElement = null;
    this.contentUrl = contentUrl;
    this.customReferenceData = customReferenceData;
    this.underEvaluation = false;
    this.serviceWindow = null;
  }

  setSlotElement(slotElement: Element) {
    this.slotElement = slotElement;
  }

  setServiceWindow(serviceWindow: unknown) {
    this.serviceWindow = serviceWindow;
  }
}

export default Context;
