import DirectCommunication from './direct-communication';
import { isCrossOrigin, resolveTopWindowContext } from './window-utils';

const EXPORTED_SESSION_COMMUNICATION_NAME = ['omid', 'v1_SessionServiceCommunication'];

const EXPORTED_VERIFICATION_COMMUNICATION_NAME = ['omid', 'v1_VerificationServiceCommunication'];

const EXPORTED_SERVICE_WINDOW_NAME = ['omidVerificationProperties', 'serviceWindow'];

function getValueForKeypath(object: any, unobfuscatedKeypath: string[]) {
  return unobfuscatedKeypath.reduce(
    (subObject, key) => subObject && subObject[key],
    object,
  );
}

function startServiceCommunication(
  serviceGlobal: any,
  exportedCommunicationName: string[],
) {
  if (!isCrossOrigin(serviceGlobal)) {
    try {
      const directCommunication = getValueForKeypath(
        serviceGlobal,
        exportedCommunicationName,
      );
      if (directCommunication) {
        return new DirectCommunication(directCommunication);
      }
    } catch (e) { /* */ }
  }
  return null;
}

function startServiceCommunicationFromCandidates(
  serviceGlobalCandidates: any[],
  exportedCommunicationName: string[],
) {
  for (let i = 0; i < serviceGlobalCandidates.length; i += 1) {
    const serviceGlobal = serviceGlobalCandidates[i];
    const communication = startServiceCommunication(
      serviceGlobal,
      exportedCommunicationName,
    );
    if (communication) {
      return communication;
    }
  }
  return null;
}

export function startSessionServiceCommunication(
  clientGlobal: any,
  serviceGlobal: any = undefined,
) {
  const serviceGlobalCandidates = [
    clientGlobal,
    resolveTopWindowContext(clientGlobal),
  ];

  if (serviceGlobal) {
    serviceGlobalCandidates.unshift(serviceGlobal);
  }

  return startServiceCommunicationFromCandidates(
    serviceGlobalCandidates,
    EXPORTED_SESSION_COMMUNICATION_NAME,
  );
}

export function startVerificationServiceCommunication(
  clientGlobal: typeof globalThis | Window,
) {
  const serviceGlobalCandidates = [];
  const providedServiceWindow = getValueForKeypath(
    clientGlobal, EXPORTED_SERVICE_WINDOW_NAME,
  );

  if (providedServiceWindow) {
    serviceGlobalCandidates.push(providedServiceWindow);
  }
  serviceGlobalCandidates.push(resolveTopWindowContext(clientGlobal));

  return startServiceCommunicationFromCandidates(
    serviceGlobalCandidates,
    EXPORTED_VERIFICATION_COMMUNICATION_NAME,
  );
}
