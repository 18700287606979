const ServiceMethodPrefix = {
  SESSION_SERVICE: 'SessionService.',
  VERIFICATION_SERVICE: 'VerificationService.',
};

const getPrefixedMethod = (unprefixedMethod: string, prefix: string) => prefix + unprefixedMethod;

export const getPrefixedVerificationServiceMethod = (unprefixedMethod: string) => getPrefixedMethod(
  unprefixedMethod,
  ServiceMethodPrefix.VERIFICATION_SERVICE,
);

export const getPrefixedSessionServiceMethod = (unprefixedMethod: string) => getPrefixedMethod(
  unprefixedMethod,
  ServiceMethodPrefix.SESSION_SERVICE,
);
