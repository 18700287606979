import { isValidVersion, versionGreaterOrEqual } from './version-utils';

const ARGS_NOT_SERIALIZED_VERSION = '1.0.3';

export function serializeMessageArgs(version: string, args: string | unknown[] | undefined) {
  if (isValidVersion(version)
      && versionGreaterOrEqual(version, ARGS_NOT_SERIALIZED_VERSION)) {
    return args;
  }
  return JSON.stringify(args);
}

export function deserializeMessageArgs(version: string, args?: any) : any[] {
  if (isValidVersion(version)
      && versionGreaterOrEqual(version, ARGS_NOT_SERIALIZED_VERSION)) {
    return args || [];
  }
  return args ? JSON.parse(args as string) : [];
}
