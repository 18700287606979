import OmidGlobal from './omid-global-provider';

const isValidWindow = (win: any = undefined) => win != null && typeof win.top !== 'undefined' && win.top != null;
const { omidGlobal } = OmidGlobal;

export const isCrossOrigin = (win: any) => {
  if (win === omidGlobal) {
    return false;
  }
  try {
    if (typeof win.location.hostname === 'undefined') {
      return true;
    }
  } catch (e) {
    return true;
  }
  return false;
};

export const resolveTopWindowContext = (win: any) => {
  if (!isValidWindow(win)) {
    return omidGlobal;
  }
  return win.top;
};

export const resolveGlobalContext = (win?: any): typeof globalThis | Window => {
  let tmp = win;
  if (typeof win === 'undefined' && typeof window !== 'undefined' && window) {
    tmp = window;
  }
  if (isValidWindow(tmp)) {
    return tmp;
  }
  return omidGlobal;
};
