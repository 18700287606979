export const CommunicationType = {
  NONE: 'NONE',
  DIRECT: 'DIRECT',
};

export const AdEventType = {
  GEOMETRY_CHANGE: 'geometryChange',
  SESSION_START: 'sessionStart',
  SESSION_ERROR: 'sessionError',
  SESSION_FINISH: 'sessionFinish',
};

export const AccessMode = {
  FULL: 'full',
};

export const CreativeType = {
  DEFINED_BY_JAVASCRIPT: 'definedByJavaScript',
  HTML_DISPLAY: 'htmlDisplay',
  NATIVE_DISPLAY: 'nativeDisplay',
};

export const ImpressionType = {
  DEFINED_BY_JAVASCRIPT: 'definedByJavaScript',
  UNSPECIFIED: 'unspecified',
  LOADED: 'loaded',
  BEGIN_TO_RENDER: 'beginToRender',
  ONE_PIXEL: 'onePixel',
  VIEWABLE: 'viewable',
  OTHER: 'other',
};

export const ErrorType = {
  GENERIC: 'generic',
  MEDIA: 'media',
};

export const OmidPartnerName = 'Referenceapp';
export const OmidPartnerVersion = '0.0.0';
