import InternalMessage from './internal-message';
import { CommunicationType } from './constants';

abstract class Communication {
  to?: any;

  onMessage?: (message: InternalMessage, from: any) => void;

  communicationType_: string;

  constructor(to?: any) {
    this.to = to;
    this.communicationType_ = CommunicationType.NONE;
  }

  abstract sendMessage(message: InternalMessage, to?: any): void;

  abstract isCrossOrigin(): boolean;

  handleMessage(message: InternalMessage, from: unknown) {
    if (this.onMessage) this.onMessage(message, from);
  }

  serialize(message: object) {
    return JSON.stringify(message);
  }

  deserialize(messageJson: string): object {
    try {
      return (JSON.parse(messageJson) as object);
    } catch {
      return {};
    }
  }

  isDirectCommunication(): boolean {
    return this.communicationType_ === CommunicationType.DIRECT;
  }
}

export default Communication;
