import OmidGlobal from '../common/omid-global-provider';

const { omidGlobal } = OmidGlobal;

const ExportedNodeKeys = {
  ROOT: 'omidSessionInterface',
  AD_EVENTS: 'adEvents',
};

const MethodNameMap = {
  sessionError: 'reportError',
};

const AdEventMethodNames = [
  'impressionOccurred',
];

class OmidJsSessionInterface {
  interfaceRoot_: typeof globalThis | Window;

  constructor(globalScope: (typeof globalThis | Window) = omidGlobal) {
    this.interfaceRoot_ = globalScope[ExportedNodeKeys.ROOT as keyof typeof globalScope];
  }

  isSupported() {
    return this.interfaceRoot_ != null;
  }

  sendMessage(methodParam: string, responseCallback: CallbackFn, argsParam: unknown[]) {
    let method = methodParam;
    let interfaceNode: any = this.interfaceRoot_;
    const args = (method === 'registerSessionObserver') ? [responseCallback] : argsParam;

    if (method in MethodNameMap) {
      method = MethodNameMap[method as keyof typeof MethodNameMap];
    }

    if (AdEventMethodNames.indexOf(method) >= 0) {
      interfaceNode = interfaceNode[ExportedNodeKeys.AD_EVENTS];
    }

    if (!interfaceNode[method]) {
      throw new Error(`Unrecognized method name: ${method}.`);
    }

    interfaceNode[method](...args);
  }
}

export default OmidJsSessionInterface;
