import Context from './context';
import { Version } from '../common/version';
import InternalMessage from '../common/internal-message';
import { startSessionServiceCommunication } from '../common/service-comunication';
import { resolveGlobalContext } from '../common/window-utils';
import { AdEventType, CreativeType, ImpressionType, ErrorType } from '../common/constants';
import { generateGuid } from '../common/guid';
import Rectangle from '../common/rectangle';
import { getPrefixedSessionServiceMethod } from '../common/service-method-utils';
import VerificationScriptResource from './verification-script-resource';
import { deserializeMessageArgs, serializeMessageArgs } from '../common/args-serde';
import OmidJsSessionInterface from './omid-session-interface';
import Communication from '../common/comunication';

const SESSION_CLIENT_VERSION = Version;

export default class AdSession {
  context_: Context;

  impressionOccurred_ = false;

  communication_;

  sessionInterface_;

  hasAdEvents_ = false;

  hasMediaEvents_ = false;

  isSessionRunning_ = false;

  creativeType_?: string | null = null;

  callbackMap_: Record<string, any> = {};

  impressionType_: any = null;

  creativeLoaded_ = false;


  constructor(
    context: Context,
    communication?: Communication,
    sessionInterface?: OmidJsSessionInterface,
  ) {
    this.context_ = context;

    const serviceWindow = this.context_.serviceWindow || undefined;

    this.communication_ = communication
        || startSessionServiceCommunication(resolveGlobalContext(), serviceWindow);

    this.sessionInterface_ = sessionInterface || new OmidJsSessionInterface();

    if (this.communication_) {
      this.communication_.onMessage = this.handleInternalMessage_.bind(this);
    }

    this.setClientInfo_();
    this.injectVerificationScripts_(context.verificationScriptResources);
    if (context.slotElement) {
      this.sendSlotElement_(context.slotElement);
    }

    this.sendContentUrl_(context.contentUrl);
    this.watchSessionEvents_();
  }

  setCreativeType(creativeType?: string) {
    if (creativeType === CreativeType.DEFINED_BY_JAVASCRIPT) {
      throw new Error(`Creative type cannot be redefined with value ${
        CreativeType.DEFINED_BY_JAVASCRIPT}`);
    }
    if (this.impressionOccurred_) {
      throw new Error('Impression has already occurred');
    }
    if (this.creativeLoaded_) {
      throw new Error('Creative has already loaded');
    }
    if (this.creativeType_
      && this.creativeType_ !== CreativeType.DEFINED_BY_JAVASCRIPT) {
      throw new Error('Creative type cannot be redefined');
    }
    if (this.creativeType_ === undefined) {
      throw new Error('Native integration is using OMID 1.2 or earlier');
    }
    this.sendOneWayMessage('setCreativeType', creativeType);
    this.creativeType_ = creativeType;
  }

  setImpressionType(impressionType?: string) {
    if (impressionType === ImpressionType.DEFINED_BY_JAVASCRIPT) {
      throw new Error(`Impression type cannot be redefined with value ${
        ImpressionType.DEFINED_BY_JAVASCRIPT}`);
    }
    if (this.impressionOccurred_) {
      throw new Error('Impression has already occurred');
    }
    if (this.creativeLoaded_) {
      throw new Error('Creative has already loaded');
    }
    if (this.impressionType_
      && this.impressionType_ !== ImpressionType.DEFINED_BY_JAVASCRIPT) {
      throw new Error('Impression type cannot be redefined');
    }
    if (this.impressionType_ === undefined) {
      throw new Error('Native integration is using OMID 1.2 or earlier');
    }
    this.sendOneWayMessage('setImpressionType', impressionType);
    this.impressionType_ = impressionType;
  }

  isSupported() {
    return Boolean(this.communication_) || this.sessionInterface_.isSupported();
  }

  isSendingElementsSupported_() {
    return this.communication_ ? this.communication_.isDirectCommunication()
      : this.sessionInterface_.isSupported();
  }

  registerSessionObserver(functionToExecute: CallbackFn) {
    this.sendMessage('registerSessionObserver', functionToExecute);
  }

  start() {
    const sessionStartContext = {
      customReferenceData: this.context_.customReferenceData,
      underEvaluation: this.context_.underEvaluation,
    };
    this.sendOneWayMessage('startSession', sessionStartContext);
  }

  finish() {
    this.sendOneWayMessage('finishSession');
  }

  error(errorType: any, message: any) {
    this.sendOneWayMessage('sessionError', errorType, message);
  }

  registerAdEvents() {
    if (this.hasAdEvents_) {
      throw new Error('AdEvents already registered.');
    }
    this.hasAdEvents_ = true;
    this.sendOneWayMessage('registerAdEvents');
  }

  sendOneWayMessage(method: string, ...args: unknown[]) {
    this.sendMessage(method, null, ...args);
  }

  sendMessage(method: string, responseCallback: CallbackFn, ...args: any[]) {
    if (this.communication_) {
      this.sendInternalMessage_(method, responseCallback, args);
      return;
    }
    if (this.sessionInterface_.isSupported()) {
      this.sendInterfaceMessage_(method, responseCallback, args);
    }
  }

  sendInternalMessage_(method: string, responseCallback: CallbackFn, args: any[]) {
    const guid = generateGuid();
    const message = new InternalMessage(
      guid, getPrefixedSessionServiceMethod(method), SESSION_CLIENT_VERSION,
      serializeMessageArgs(SESSION_CLIENT_VERSION, args),
    );

    if (responseCallback) {
      this.callbackMap_[guid] = responseCallback;
    }

    if (this.communication_) {
      this.communication_.sendMessage(message);
    }
  }

  handleInternalMessage_(message: InternalMessage, from: any) {
    const { method, guid, args } = message;
    if (method === 'response' && this.callbackMap_[guid]) {
      const parsedArgs = deserializeMessageArgs(SESSION_CLIENT_VERSION, args);
      this.callbackMap_[guid].apply(this, parsedArgs);
    }
  }

  sendInterfaceMessage_(method: string, responseCallback: CallbackFn, args: any[]) {
    try {
      this.sessionInterface_.sendMessage(method, responseCallback, args);
    } catch (error) {
      console.log('Failed to communicate with SessionInterface with error:', error);
    }
  }

  assertSessionRunning() {
    if (!this.isSessionRunning_) {
      throw new Error('Session not started.');
    }
  }

  impressionOccurred() {
    if (this.creativeType_ === CreativeType.DEFINED_BY_JAVASCRIPT) {
      throw new Error('Creative type has not been redefined');
    }
    if (this.impressionType_ === ImpressionType.DEFINED_BY_JAVASCRIPT) {
      throw new Error('Impression type has not been redefined');
    }
    this.impressionOccurred_ = true;
  }

  creativeLoaded() {
    if (this.creativeType_ === CreativeType.DEFINED_BY_JAVASCRIPT) {
      throw new Error('Creative type has not been redefined');
    }
    if (this.impressionType_ === ImpressionType.DEFINED_BY_JAVASCRIPT) {
      throw new Error('Impression type has not been redefined');
    }
    this.creativeLoaded_ = true;
  }

  setClientInfo_() {
    this.sendOneWayMessage('setClientInfo', SESSION_CLIENT_VERSION,
      this.context_.partner.name, this.context_.partner.version);
  }

  injectVerificationScripts_(verificationScriptResources: VerificationScriptResource[]) {
    if (!verificationScriptResources) return;
    const resources = verificationScriptResources.map(r => r.serialize());
    this.sendOneWayMessage('injectVerificationScriptResources', resources);
  }

  sendSlotElement_(element: Element) {
    this.sendElement_(element, 'setSlotElement');
  }

  sendElement_(element: Element, method: string) {
    if (!this.isSendingElementsSupported_()) {
      this.error(
        ErrorType.GENERIC,
        `Session Client ${method} called when communication is cross-origin`,
      );
      return;
    }
    this.sendOneWayMessage(method, element);
  }

  sendContentUrl_(contentUrl?: string | null) {
    if (!contentUrl) {
      return;
    }
    this.sendOneWayMessage('setContentUrl', contentUrl);
  }

  setElementBounds(elementBounds: Rectangle) {
    this.sendOneWayMessage('setElementBounds', elementBounds);
  }

  watchSessionEvents_() {
    this.registerSessionObserver((event: any) => {
      if (event.type === AdEventType.SESSION_START) {
        this.isSessionRunning_ = true;
        this.creativeType_ = event.data.creativeType;
        this.impressionType_ = event.data.impressionType;
      }
      if (event.type === AdEventType.SESSION_FINISH) {
        this.isSessionRunning_ = false;
      }
    });
  }
}
